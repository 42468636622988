import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import styled, { css } from 'styled-components';
import _, { forEach } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isDesktop, isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import Pagination from 'components/UI/Pagination';
import Footer from 'components/UI/Footer';
import CardProduct from 'components/UI/Card/AdditionCardProduct';
import { convertDate2StringDateTime } from 'utils/_dateHandler';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { logGetRecommendData } from 'utils/_omsLog';

const mixinResultList = css`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 28px;

  @media (max-width: ${md}) {
    gap: 8px;
    margin-bottom: 0;
  }
`;

const mixinCardProduct = css`
  flex: 0 0 calc((100% - 4 * 12px) / 5);
  min-height: 291px;

  @media (max-width: ${md}) {
    flex: 0 0 calc((100% - 2 * 8px) / 3);
    min-height: 184px;
  }
`;

const Container = styled.div`
  width: 1080px;
  margin: ${(props) => `${props.headerHeight}px`} auto 0;

  @media (max-width: ${md}) {
    width: 100%;
  }

  .skeleton-search-wrapper {
    ${mixinResultList};

    .skeleton-search {
      ${mixinCardProduct};
    }
  }
`;

const StyledHeader = styled(Header)`
  .address-wrapper,
  .switch-delivery-wrapper {
    @media (max-width: ${md}) {
      display: none;
    }
  }

  .title {
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

const StyledPagination = styled(Pagination)`
  margin-bottom: 10px;
`;

const StyledCardProduct = styled(CardProduct)`
  ${mixinCardProduct};
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    margin-bottom: 5px;
    align-items: center;
  }
`;

const SearchText = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #333333;

  @media (max-width: ${md}) {
    font-size: 12px;
  }

  > span {
    font-size: 32px;
    font-weight: 700;

    @media (max-width: ${md}) {
      font-size: 18px;
    }
  }
`;

const ResultList = styled.div`
  ${mixinResultList};
`;

const SwitchList = styled.div`
  display: flex;
  gap: 0 12px;
`;

const SwitchBtn = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #3b3516;
  cursor: pointer;
  padding: 6px 4px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;

  @media (max-width: ${md}) {
    font-size: 14px;
  }

  ${(props) =>
    props.isActive &&
    css`
      font-weight: 500;
      border-bottom: 2px solid #5fd2da;
    `}
`;

const Section = styled.div`
  @media (max-width: ${md}) {
    background-color: #fff;
    padding: 9px 14px;
  }
`;

let currentPage = 1;
let total = 0;
let totalPage = 0;
const perPage = 25;
let arrProducts = [];

const Recommend = ({ location }) => {
  const api = useAPI();
  const resultList = useRef();
  const [domLoaded, setDomLoaded] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    per_page: 25,
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recommendList, setRecommendList] = useState([]);
  const [recommendItemsData, setRecommendItemsData] = useState({});
  const recommendItems = useSelector((state) =>
    getters.getRecommendItems(state)
  );

  const paginationConfig = {
    perPage: 25, //一頁幾筆
  };

  const fetchRecommendItemList = () => {
    setIsLoading(true);
    store.dispatch(actions.setRecommendItems([]));
    store.dispatch(actions.setRecommendData({}));
    api
      .getRecommendItems()
      .then((res) => {
        store.dispatch(actions.setRecommendItems(res.rows));
        store.dispatch(actions.setRecommendData(res));
        logGetRecommendData(res);
        if (!isMobile) {
          divideData(res.rows);
        }
        setIsLoading(false);
      })
      .catch((error) => {});
  };

  const renderResultList = () => {
    return (
      <ResultList>
        {isMobile &&
          recommendItems?.map((product, index) => {
            return (
              <StyledCardProduct
                key={`StyledCardProduct-${index}`}
                type={'recommend'}
                goToPage={`/product?pid=${product.productId}`}
                onChangeCount={(count, productId) => {
                  // handleChangeCount(count, productId);
                }}
                productData={product}
                onGo2Detail={(id) => {}}
              />
            );
          })}
        {isDesktop &&
          recommendItemsData[currentPage]?.map((product, index) => {
            return (
              <StyledCardProduct
                key={`StyledCardProduct-${index}`}
                type={'recommend'}
                goToPage={`/product?pid=${product.productId}`}
                onChangeCount={(count, productId) => {
                  // handleChangeCount(count, productId);
                }}
                productData={product}
                onGo2Detail={(id) => {}}
              />
            );
          })}
      </ResultList>
    );
  };

  const divideData = (arrData) => {
    const perPage = paginationConfig.perPage;
    const pagTotal = Math.ceil(arrData.length / perPage);

    const data = {};
    for (let i = 0; i < pagTotal; i++) {
      const start = i * perPage;
      const end = start + perPage;
      data[i + 1] = arrData.slice(start, end);
    }

    setRecommendItemsData(data);
    setPageInfo({
      page: 1,
      per_page: perPage,
      total: arrData.length,
    });
  };

  // 桌機版
  useEffect(() => {
    fetchRecommendItemList();
  }, []);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
      {domLoaded && (
        <Container headerHeight={headerHeight}>
          <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
            <StyledHeader title="搜尋結果" onRefresh={() => {}} />
          </HeaderWrapper>
          <Section>
            {recommendItems && recommendItems.length >= 0 && (
              <TitleBar>
                <SearchText>
                  <span>推薦商品</span>
                </SearchText>
              </TitleBar>
            )}

            {isDesktop && isLoading && (
              <Skeleton
                containerClassName="skeleton-search-wrapper"
                className="skeleton-search"
                count={30}
                inline
              />
            )}
            {isDesktop && recommendItems.length >= 0 && !isLoading && (
              <>
                {renderResultList()}
                <StyledPagination
                  total={pageInfo.total}
                  pageSize={Number(pageInfo.per_page)}
                  current={Number(pageInfo.page)}
                  onPageChange={(page) => {
                    //切換頁面
                    setCurrentPage(page);
                    setPageInfo({
                      page: page,
                      per_page: paginationConfig.perPage,
                      total: recommendItems.length,
                    });
                  }}
                />
              </>
            )}

            {isMobile && isLoading && (
              <Skeleton
                containerClassName="skeleton-search-wrapper"
                className="skeleton-search"
                count={30}
                inline
              />
            )}
            {isMobile && recommendItems.length >= 0 && !isLoading && (
              <InfiniteScroll
                ref={resultList}
                dataLength={recommendItems.length}
                next={() => {}}
                hasMore={false}
                style={{ height: '100%', overflow: 'unset' }}
                loader={<h6>資料載入中...請稍候</h6>}
              >
                {renderResultList()}
              </InfiniteScroll>
            )}
          </Section>
        </Container>
      )}
      <Footer />
    </>
  );
};

export default Recommend;
